import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "cat.jpg" }) {
        childImageSharp {
          fixed(width: 290) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Img
      title="Paws &amp; Tails astronaut pet portrait"
      fixed={data.placeholderImage.childImageSharp.fixed}
    />
  )
}

const Page = () => (
  <Layout>
    <SEO title="About Paws &amp; Tails AI" />

    <div className={`lg:text-lg container mt-48 md:mt-64`}>
      <Image />
      <h1 className="block montserrat text-3xl my-4 font-semibold">
        We wanted to create an app that everyone can relate to who love pets.
      </h1>
      <p className="text-xl md:text-2xl my-4">
        Our inspiration draws from our own relationship with our own furry
        friends. They continue to be an pivotal part of our lives and continue
        to enrich of our families. We thought what better way to honor our pets
        than by doing this app.
      </p>
      <p className="text-xl md:text-2xl my-3">
        By taking a picture we're using artificial Intelligence for pet portrait
        generation. We also use object detection to classify animals and a
        secret sauce for artistic expression. Coming soon users will be able
        pick different costumes, remove the watermark and more. Additionally
        you'll have the option to download your HD pet portrait for printing.
      </p>
    </div>
  </Layout>
)

export default Page
